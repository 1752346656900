


















import {
  Component,
  Vue
} from "vue-property-decorator"
import {
  api
} from '@/api'
import {
  setToken,
  setComToken,
  setUserInfo
} from '@/utils/auth'
import { UserCookieKey } from '@/utils/config'

@Component({
  name: 'login-index',
})
export default class LoginIndex extends Vue {
  private loginForm: any = {
    username: '',
    password: ''
  }

  /**
   * 登录
   */
  private doLogin() {
    if (!this.loginForm.username || !this.loginForm.password) {
      this.$weui.topTips('账号密码不能为空')
      return
    }
    var loading: any = this.$weui.loading('登录中...');
    api.login(this.loginForm).then((res: any) => {
      setToken(res.msg.token)
      setUserInfo(res.msg)
      setComToken(UserCookieKey, JSON.stringify({id: res.msg.id, realName: res.msg.realName}))
      this.$router.replace({path: '/'})
      loading.hide()
    }).catch((err: any) => {
      console.log(err)
      loading.hide()
      this.$weui.topTips(err.msg)
    })
  }
}
